import "bootstrap/dist/css/bootstrap.min.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/footer";
import "./BlogHome.css";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Description } from "@mui/icons-material";
function BlogHome() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [msg_subject, setmsg_subject] = useState("");
  const [message, setmessage] = useState("");
  const navigate = useNavigate();
  function AllBlogDataArray(data) {
    navigate("/our-blog/"+data.id, { state: { BlogData: data } });
  }
  function handalSubmit() {
    if(!name){
      alert("Name field is required");
      return false;
    }
    if(!email){
      alert("Email field is required");
      return false;
    }
    if(!phone_number){
      alert("PhoneNumber field is required");
      return false;
    }
    if(!msg_subject){
      alert("Subject field is required");
      return false;
    }
    if(!message){
      alert("Message field is required");
      return false;
    }
    axios
      .post("https://www.reptileindia.co.in/laravel_website/api/website_send_email", {
        name: name,
        email:email,
        phone_number:phone_number,
        msg_subject:msg_subject,
        message:message
      })
      .then((res) => {
        console.log("res----------->",res);
        if (res.data.result){
          alert(res.data.message);
          setname("");
          setemail("");
          setphone_number("");
          setmsg_subject("");
          setmessage("");
          window.scrollTo(0, 0);
      }
      });
  }
  
  function handalSubmit_1() {
    if (!email) {
      alert("Email field is required");
      return false;
    }
    axios
      .post(
        "https://www.reptileindia.co.in/laravel_website/api/website_send_email",
        {
          name: email,
          email: email,
          phone_number: "0000000000",
          msg_subject: "BUSINESS",
          message: "READY TO BUILD A REAL ONLINE BUSINESS ?",
        }
      )
      .then((res) => {
        console.log("res----------->", res);
        if (res.data.result) {
          alert(res.data.message);
          setname("");
          setemail("");
          setphone_number("");
          setmsg_subject("");
          setmessage("");
          window.scrollTo(0, 0);
        }
      });
  }
  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1025: { items: 2 },
  };
  const items = [
    <div className="item">
      <img
        src="/assets/blogimage/Finance_Locker_Apps.jpg"
        alt="images"
        className="images_section"
      />
    </div>,
    <div className="item">
      <img
        src="/assets/blogimage/Cloud-Service.jpg"
        alt="images"
        className="images_section"
      />
    </div>,
    <div className="item">
      <img
        src="/assets/blogimage/Hisab_Book.png"
        alt="images"
        className="images_section"
      />
    </div>,
    <div className="item">
      <img
        src="/assets/blogimage/Whatsapp-Business-API.jpg"
        alt="images"
        className="images_section"
      />
    </div>,
    <div className="item">
      <img
        src="/assets/blogimage/SEO.jpg"
        alt="images"
        className="images_section"
      />
    </div>,
    <div className="item">
      <img
        src="/assets/blogimage/SMM.png"
        alt="images"
        className="images_section"
      />
    </div>,
  ];
  const imagesText = [
    {
      Heading: "Cloud Service",
      Description:
        "Transforming the Future of Business and Technology Few technological advancements have had as much of an influence as cloud.....",
      images: "/assets/blogimage/Cloud-Service.jpg",
      Description1:'Welcome to our latest blog post on Cloud Service',
      ContentAll:'Transforming the Future of Business and Technology Few technological advancements have had as much of an influence as cloud computing in the ever changing field of technology. Because of the clouds capacity to provide scalability, agility, and unmatched access to data and apps, corporate operations have completely changed. However, what is cloud computing really, and why is it such a revolutionary technology?',
      Date: "29 june 2024",
      FinanceQustion:'What is Cloud Computing?',
      FinanceAnswer:'The provision of computer services—such as servers, storage, databases, networking, software, and analytics—through the Internet, or "the cloud," is the fundamental idea behind cloud computing. Using this strategy, companies can store and retrieve data and applications from remote servers instead of local servers or desktop computers.',
      pOne:'Three primary categories of cloud services exist',
      pTow:'Via the internet, infrastructure as a service (IaaS) offers virtualized computer resources. Consider it as renting servers and storage from a cloud provider for your IT infrastructure.',
      pThree:'Platform as a Service (PaaS): Provides online access to hardware and software tools, usually for application development. It resembles an application development and deployment environment that is ready to use.',
      pFour:'Software as a Service (SaaS): Provides software programs via the internet in exchange for a subscription. As a result, businesses are no longer required to set up and operate apps on their own PCs or data centres.',
      pSix: "The Cloud Advantage: Why Businesses are Moving to the Cloud",
       ulsection:'Economy of Cost',
      liAnswer:'The cost savings are among the strongest arguments in favor of cloud computing adoption. Traditional IT infrastructure needs a large initial outlay as well as continuous upkeep. These expenses can be avoided by enterprises with cloud computing. They only pay for the resources they really utilize, which they can adjust as needed. Startups and small enterprises can benefit greatly from this pay-as-you-go approach because it allows them to utilize enterprise-level infrastructure without the high cost.',
      lianswertow:'2. Flexibility and Scalability',
      lianswerthree:'The scalability of cloud computing is unmatched. An organizations IT requirements grow as it expands. Hardware or infrastructure modifications are not necessary while using the cloud because it makes resource scaling simple. Cloud services allow you to quickly adapt to changing demand, whether your introducing a new product or dealing with a seasonal spike in traffic.',
      lianswerfoue:'3. Mobility and Accessibility',
      piAnswer:'Data and apps are available on any internet-connected device, at any time, and from any location in the world thanks to the cloud. This degree of accessibility facilitates worldwide cooperation and remote work, both of which are becoming more and more crucial in today linked world.',
      pSeven: "Disaster Recovery and Business ContinuityMoney Management Techniques",
      PsevenAnswer:'Strong disaster recovery options are provided by cloud services, guaranteeing that data is backed up and quickly recoverable in the case of an outage. This reduces downtime and maintains company continuity even in the face of unanticipated occurrences, such as cyberattacks or natural catastrophes.'
    },
    {
      Heading: "UX UI Design",
      Description:
        "User Interface (UI) and User Experience (UX) design play a critical role in developing interesting, user-friendly websites......",
      images: "/assets/blogimage/UX UI Design.png",
      Date: "29 june 2024",
      Description1:'Welcome to our latest blog post on UX UI Design',
      ContentAll:'User Interface (UI) and User Experience (UX) design play a critical role in developing interesting, user-friendly websites and applications in the quickly changing digital ecosystem. Understanding and putting into practice the most recent UI/UX trends and ideas as we approach 2024 can mean the difference between a product that succeeds and one that fades into obscurity. To help you stay competitive, this blog explores key UI/UX design ideas and new developments.',
      FinanceQustionsss:'The Basics of User Interface/UX Design Uderstanding the fundamental ideas that underpin successful UI/UX design is essential before digging into the newest trends.',
      FinanceQustion:'UCD, or user-centred design',
      FinanceAnswer:'The user is at the centre of UI/UX design. Understanding the wants, preferences, and limits of the user at every level of the design process is the main goal of user-centered design. By using this method, the end product is guaranteed to be user-friendly and intuitive.',
      pOne:'1. Clarity and Simplicity',
      pTow:'The goal of simplicity in design is to eliminate extraneous details to produce a clear, uncomplicated user experience. Users can explore and interact with the product more easily when it is designed in a clear and simple manner, which lessens cognitive burden on them.',
      pSeven:'Equitable',
      PsevenAnswer:'Users can better anticipate how things will work when UI elements, including buttons, icons, and typography, are consistent, which improves usability and lowers the learning curve.',
      pSix: "Hierarchical Visualisation",
      liAnswer:'Users are guided through content in order of significance through the effective usage of visual hierarchy. The strategic use of color, size, layout, and spacing can accomplish this.'
    
    },
    {
      Heading: "Finance Locker Apps",
      Description:
        "Finance Locker, the place to go if you want to become an expert in personal finance and open the doors to a safe and wealthy future.....",
      images: "/assets/blogimage/Finance_Locker_Apps.jpg",
      ContentAll:
        "Greetings from Finance Locker, the place to go if you want to become an expert in personal finance and open the doors to a safe and wealthy future. Finance Locker is here to help you every step of the way, whether your starting out in finance or want to improve your wealth management techniques.",
      Description1: "Welcome to our latest blog post on Finance Locker Apps",
      Date: "29 june 2024",
      FinanceQustion: "Why Finance Locker",
      FinanceAnswer:
        "Keeping track of finances in the fast-paced world of today can be like navigating a maze. Budget balancing, retirement planning, prudent investing, and guaranteeing a strong financial safety net can easily overwhelm oneself. Finance Locker can help with that.",
      pOne: "Our goal is to demystify money so you can take charge of your financial future by giving you the knowledge, resources, and tools you need. What distinguishes us is this",
      pTow: "Extensive Financial Advice: We address a wide range of financial subjects specifically designed to match your needs, from advanced investment techniques to budgeting advice.",
      pThree:
        "Professional insights: To assist you in making well-informed decisions, our team of financial specialists provides you with the most recent guidance and tactics.",
      pFour:
        "User-Friendly Tools: To make money management activities easier, we offer financial trackers, budgeting tools, and calculators that are simple to use.",
      pFive:
        "Community Support: On your path to financial independence, come along with a thriving group of like-minded people. Together, celebrate successes, ask for guidance, and exchange experiences",
      pSix: "Core Services",
       ulsection: " Personal Budgeting",
      liAnswer:
        "The foundation of sound financial management is a budget. Making a budget should be liberating rather than burdensome, in our opinion at Finance Locker. We created these budgeting resources and instructions to assist you",
      lianswertow: "Effectively manage your income and expenses",
      lianswerthree:
        "Determine spending trends and regions in need of development.",
      lianswerfoue: "Set attainable savings targets and easily meet them.",
      pSeven: "Money Management Techniques",
      PsevenAnswer:
        "Saving money involves more than just setting money away; it also entails building an emergency fund. We offer advice on how to save for major purchases, accumulate an emergency fund, and make long-term plans like retirement or college.",
    },
  ];
  const LinkSection = [
    {
      label: "All",
      link: "/All",
    },
    {
      label: "React Native",
      link: "/Restaurant App Development",
    },
    {
      label: "Data Science & Analytics",
      link: "/Restaurant App Development",
    },
    {
      label: "Cloud Computing",
      link: "/Restaurant App Development",
    },
    {
      label: "React Native",
      link: "/Restaurant App Development",
    },
    {
      label: "Android Development",
      link: "/Restaurant App Development",
    },
  ];
  const BlogData = [
    {
      id: "Mobile-App-Development",
      Heading: "Mobile App Development",
      Description:
        "Welcome to our latest blog post on Mobile App Development! ",
        Description1: "Welcome to our latest blog post on Mobile App Development!",
      conrtent:
        "In today fast-paced digital landscape, mobile apps have become an essential tool for businesses looking.....",
      Date: "29 june 2024",
      Comment: "71",
      images: "/assets/blogimage/Mobile-App-Development.jpg",
      ContentAll:'In todays fast-paced digital landscape, mobile apps have become an essential tool for businesses looking to connect with their customers and stay ahead of the competition. Whether your a seasoned developer or just starting out, understanding the ins and outs of mobile app development can help you create powerful and user-friendly applications that resonate with your target audience.',
      FinanceQustion:'Key Points',
      FinanceAnswer:'1. Market Growth and Trends',
      pOne:'Rapid expansion of the mobile app market.',
      pTow:'Increasing demand for innovative and user-friendly applications.',
      pThree:'Trends like AI integration, IoT, and AR/VR shaping app development.',
      pFour:'2. Platforms and Technologies',
      pFive:'Advances in programming languages and development environments. Cross-platform development tools and frameworks.Dominance of iOS and Android platforms.',
      pSix:'Development Processes',
      liAnswer:'Agile and DevOps methodologies. Stages of app development: ideation, design, development, testing, and deployment. Continuous integration and delivery.',
      pSeven:'Future Outlook',
      PsevenAnswer:'Emerging technologies and their impact on mobile app development. Predictions for the next decade.The role of mobile apps in a connected world.'
    },
    {
      id:"Whatsapp-Business-API ",
      Heading:"Whatsapp Business API ",
      Description: "Welcome to our latest blog post on Whatsapp Business API",
      conrtent:
        "Are you looking to take your business communication to the next level? Look no further than the power of WhatsApp...",
      Date: "29 june 2024",
      Comment: "71",
      images: "/assets/blogimage/Whatsapp-Business-API.jpg",
      Description1: "Welcome to our latest blog post on Whatsapp Business API",
      ContentAll:'Are you looking to take your business communication to the next level? Look no further than the power of WhatsApp Business API service providers! In this blog post, we will dive into the world of WhatsApp Business API and how partnering with a reliable service provider can transform the way you connect with your customers. From enhanced customer support to streamlined marketing campaigns, we will explore the benefits of utilizing WhatsApp Business API services. Get ready to learn how you can leverage this dynamic platform to boost your business growth and engagement like never before!',
      FinanceQustion:'Introduction',
      FinanceAnswer:'Brief explanation of WhatsApp Business API <br /> Importance of using WhatsApp for business communication',
      pOne:'Subscription Plans for WhatsApp Business API',
      pTow:'   A. Overview of different subscription plans available <br />B. Pros and cons of each plan <br />C. How to choose the right plan for your business needs', 
      pThree:'Free Conversations Feature',
      pFour:'   A. Explanation of how businesses can engage in free conversations with customers <br />B. Benefits of using this feature for customer service and building relationships',
      pSix:'Services Messaging',
      liAnswer:'   A. Importance of services messaging for businesses <br />B. How businesses can effectively use services messaging on WhatsApp',
      pSeven:'Conclusion',
      PsevenAnswer:'   A. Recap of the benefits of using WhatsApp Business API for businesses <br />B. Encouragement for businesses to explore the various features and capabilities of WhatsApp Business API for their marketing and communication needs.',
    },
    {
      id:"Web-development",
      Heading:"Web development",
      Description: "Welcome to our latest blog post on Web development",
      conrtent:
        "Web development refers to the process of building and maintaining websites and web applications.......",
        ContentAll:'Web development refers to the process of building and maintaining websites and web applications. It encompasses everything from creating simple static web pages to complex dynamic web applications that interact with databases and servers.',

      Date: "29 june 2024",
      Comment: "71",
      images: "/assets/blogimage/Web_Dev.png",
      Description1:'web development',
      FinanceQustion:'Career Opportunities',
      pOne:'Front-End Developer : Focuses on creating user interfaces and experiences.',
      pTow:'Back-End Developer : Manages server-side logic and database interactions.',
      pThree:'Full-Stack Developer : Has skills in both front-end and back-end development.',
      pFour:'UI/UX Designer: Designs user interfaces and focuses on user experience.',
      pSix:'Development Process',
      liAnswer:'Planning and Design: Understanding requirements, creating wireframes, and designing user interfaces. <br /> Development: Writing code using appropriate languages and frameworks to implement the design and functionality.<br />Testing: Performing various tests (unit tests, integration tests, etc.) to ensure the website or application functions correctly.<br />Deployment: Publishing the website or application on servers or hosting platforms to make it accessible to users.',
      pSeven:'Conclusion',
      PsevenAnswer:'Web development is a dynamic field that continues to evolve with new technologies and frameworks. Whether you interested in creating visually appealing interfaces, managing server-side logic, or both, web development offers a range of opportunities for building modern web applications and contributing to the digital world.'
      
    },
    {
      id: "Tracko-Application",
      Heading: "Tracko Application",
      Description: "Welcome to our latest blog post on  tracko Application",
      Description1: "Welcome to our latest blog post on  tracko Application",
      conrtent:
        "Reptile Tracko is a comprehensive mobile application that combines location tracking, attendance management, task management, and customer management....",
      Date: "29 june 2024",
      Comment: "71",
      images: "/assets/blogimage/Tracking-Application.jpg",
      ContentAll:'Welcome to our latest blog post all about employee tracking applications! Are you looking for a solution to streamline your workforce management and boost productivity? Look no further than employee tracking applications. In this post, we will delve into the world of employee tracking apps, exploring their benefits, features, and how they can revolutionize the way you manage your team. From GPS tracking to task management and timesheets, we got you covered. Stay tuned to learn more about how these apps can help your business thrive!',
      FinanceQustion:' Introduction',
      FinanceAnswer:' A. Explanation of employee tracking application <br />   B. Importance of tracking employees in organizations',
      pOne:'Features of the Employee Tracking Application',
      pTow:'  A. Attendance management<br /> B. Leave management<br /> C. Task management<br /> D. Other useful features',
      pThree:'Benefits of Using an Employee Tracking Application',
      pFour:'    A. Improved efficiency and productivity<br /> B. Better management of employee time and tasks<br /> C. Enhanced HR processes<br /> D. Increased accountability and transparency',
      pSix:'How to Implement the Employee Tracking Application in Your Organization',
      liAnswer:'   A. Software options and considerations <br /> B. Training and onboarding process <br /> C. Data security and privacy measures',
      ulsection:'Case Studies or Testimonials of Organizations Using the Employee Tracking Application Successfully',
      pSeven:'Conclusion',
      PsevenAnswer:'  A. Recap of the benefits of using an employee tracking application <br /> B. Encouragement to consider implementing the software in organizations <br /> C. Closing thoughts on the future of employee tracking technology'




    },
    {
      id: "Search-Engine-Optimization(SEO)",
      Heading: "Search Engine Optimization(SEO)",
      Description:
        "Welcome to our latest blog post on search engine optimization",
      conrtent:
        "Making an impression on search engine results pages is more important than ever in the current digital environment.....",
      Date: "29 june 2024",
      Comment: "71",
      images: "/assets/blogimage/SMM.png",
      Description:
        "Welcome to our latest blog post on Mobile App Development! ",
        Description1: "Welcome to our latest blog post on Mobile App Development!",
        conrtent:'In the current digital era, having a strong social media presence is crucial for both people and companies. A competitive advantage......',
        ContentAll:'In the current digital era, having a strong social media presence is crucial for both people and companies. A competitive advantage can be gained by staying ahead of the curve as we approach 2024. These 10 practical suggestions can help you increase your social media presence this year, whether your goal is to expand your business or develop your personal brand.',
      FinanceQustion:'Embrace Short-Form Video Content',
      FinanceAnswer:'The majority of social media content is still made up of short videos. Bite-sized content is huge right now because to platforms like YouTube Shorts, Instagram Reels, and TikTok. To draw viewers in and increase engagement, make brief, funny, and captivating videos.',
      pOne:' Leverage AI and Automation Tools',
      pTow:' Tools for automation and artificial intelligence can improve your content strategy and save time. Make greater sense of your audience by using AI-driven analytics to plan posts and even come up with ideas for new content. Use tools such as ChatGPT to help you plan and improve your posts.',
      pThree:'Prioritise Authenticity',
      pFour:'Genuineness is coveted by audiences. Post personal anecdotes, behind-the-scenes photos, and sincere conversations. To better connect with your fans, share the human side of your brand or personal journey.',
     pSix:'Invest in High-Quality Visuals',
     liAnswer:'Visuals of the highest caliber can elevate your content. Make quality design, photography, and video production investments. To improve your visual material, experiment with photo editing programs and graphic design tools such as Canva.',
     pSeven:'Analyse and Adapt Your Strategy',
     PsevenAnswer:'Examine your social media performance on a regular basis to determine what is and is not working. Track conversions, reach, and engagement with analytics tools. Utilize these findings to modify your approach and enhance your social media presence over time.'
























    },
  ];
  const leftBlogData = [
    {
      images: "/assets/blogimage/grocery-app-developer.jpeg",
      button: "Get In Touch",
    },
    {
      images: "/assets/blogimage/E-Learning App Development20.jpg",
      button: "Get In Touch",
    },
    {
      images: "/assets/blogimage/mobile.png",
      button: "Get In Touch",
    },
    {
      images: "/assets/blogimage/blog.jpg",
      button: "Get In Touch",
    },
    {
      images: "/assets/blogimage/Hire Dedicated Mobile App Developer (2).jpg",
      button: "Get In Touch",
    },
  ];
  return (
    <>
      <Navbar />
      <div className="Main_top_section">
        <div className="container header_section">
          <div className="row">
            <div
              className="col-7 Blog_text_section"
              style={{ padding: "0", margin: "0" }}
            >
              <h2>
                READY TO BUILD A <br></br>REAL ONLINE <br></br>BUSINESS?
              </h2>
              <p>
                Subscribe to the Niche Pursuits Newsletter to learn strategies
                and tactics from successful Internet Entrepreneurs 3X a week.
                Plus, get online side hustle ideas that actually work.
              </p>

              <form>
                <input
                  type="email"
                  placeholder="Type Here Email"
                  name="Email"
                  value={email}
                  onChange={(e)=>{setemail(e.target.value) }}
                  required
                />
                <input
                  type="button"
                  value="Submit"
                  className="subimt_section"
                  onClick={()=>{ handalSubmit_1() }}
                />
                <p>We do not sell or share your information with anyone.</p>
              </form>
            </div>
            <div
              className="col-5 slide_images_section"
              style={{ padding: "0", margin: "0" }}
            >
              <h4>Our Latest Blog</h4>
              <AliceCarousel
                autoPlay
                mouseTracking
                items={items}
                responsive={responsive}
                controlsStrategy="alternate"
                animationDuration={1000}
                animationType="fadeout"
                infinite
                touchTracking={false}
                disableDotsControls
                disableButtonsControls
              />
            </div>
          </div>
        </div>
      </div>
      <div className="blog_section_secound">
        <div className="container blog_section">
          <div className="row">
            {imagesText.map((item, index) => (
              <div
                className="col-4 Blog_section_main"
                key={index}
                onClick={() => AllBlogDataArray(item)}
              >
                <div>
                  <img src={item.images} alt="images" />
                  <h4>{item.Heading}</h4>
                  <h6>{item.Description}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="container navlink_section">
          <div className="row">
            {LinkSection.map((items, index) => (
              <div className="col-2 link_section" key={index}>
                <p>{items.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={"container blog_top_left_section "}>
        <div className="row">
          <div className="col-8 Blog_images_section">
            {BlogData.map((item, index) => (
              <div
                className="col-12 Blog_images_section"
                key={index}
                onClick={() => AllBlogDataArray(item)}
              >
                <div className="images_blog">
                  <div className="images_section_blog">
                    <img src={item.images} alt="Blog Images" />
                  </div>
                  <div className="blog_content_section">
                    <h5>{item.heading}</h5>
                    <h4>{item.Description}</h4>
                    <p>
                      {item.conrtent}
                      <span style={{ color: "orangered" }}>Read More</span>
                    </p>
                    <div className="icon_section">
                      <h6>
                        <i class="fa fa-calendar" aria-hidden="true"></i>{" "}
                        {item.Date}
                      </h6>
                      <h6>
                        <i class="fa fa-commenting-o" aria-hidden="true"></i>{" "}
                        {item.Comment}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="col-4 left_blog_section">
            {leftBlogData.map((item, index) => (
              <div className="col-12 left_blog_section">
                <div className="left_section_images">
                  <img src={item.images} alt="images" />
                  <button>{item.button}</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="Form_section">
        <div className="five_section_form">
          <div className="container">
            <div className="row">
              <div className="col-md-6 form_images_section">
                <div className="icon_section_text">
                  <p>
                    Explore the world of reptiles with Reptile Tracko - your
                    ultimate companion for reptile enthusiasts. Discover a
                    wealth of information, resources, and tools to help you
                    learn more about these fascinating creatures and enhance
                    your reptile-keeping experience.
                  </p>
                  <div className="fa_section">
                    <i class="fa fa-street-view" aria-hidden="true"></i>
                    <h6>
                      Carecone Technologies Private Limited <br></br>265, 2nd
                      Floor, Aggarwal City Plaza
                    </h6>
                  </div>

                  <div className="fa_section">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                    <h6>+91 9311572747</h6>
                  </div>

                  <div className="fa_section">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    <h6>sales@reptileindia.co.in</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 form_section_right">
                <div>
                  <div className="top_section">
                    <h2>Interested in Working With Us?</h2>
                    <p>
                      Drop us a line. We'd love to know more about your project.
                    </p>
                  </div>
                  <form>
                    <div className="main_form_section">
                      <div>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name*"
                          value={name}
                          onChange={(e) => {
                            setname(e.target.value);
                          }}
                        />

                        <input
                          type="email"
                          name="name"
                          placeholder="Email Address*"
                          value={email}
                          onChange={(e) => {
                            setemail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="main_form_section">
                      <div>
                        <input
                          type="number"
                          name="name"
                          placeholder="Phone Number*"
                          value={phone_number}
                          onChange={(e) => {
                            setphone_number(e.target.value);
                          }}
                        />
                        <input
                          type="text"
                          name="name"
                          placeholder="Subject*"
                          value={msg_subject}
                          onChange={(e) => {
                            setmsg_subject(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <textarea
                      name=""
                      id=""
                      placeholder="Message*"
                      value={message}
                      onChange={(e) => {
                        setmessage(e.target.value);
                      }}
                    />

                    <input
                      type="button"
                      value="Submit"
                      className="Submit"
                      onClick={() => {
                        handalSubmit();
                      }}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default BlogHome;
