import axios from "axios";
import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./index.css";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/footer";
import "bootstrap/dist/css/bootstrap.min.css";
const Products = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [msg_subject, setmsg_subject] = useState("");
  const [message, setmessage] = useState("");

  function handalSubmit() {
    if (!name) {
      alert("Name field is required");
      return false;
    }
    if (!email) {
      alert("Email field is required");
      return false;
    }
    if (!phone_number) {
      alert("PhoneNumber field is required");
      return false;
    }
    if (!msg_subject) {
      alert("Subject field is required");
      return false;
    }
    if (!message) {
      alert("Message field is required");
      return false;
    }
    axios
      .post(
        "https://www.reptileindia.co.in/laravel_website/api/website_send_email",
        {
          name: name,
          email: email,
          phone_number: phone_number,
          msg_subject: msg_subject,
          message: message,
        }
      )
      .then((res) => {
        console.log("res----------->", res);
        if (res.data.result) {
          alert(res.data.message);
          setname("");
          setemail("");
          setphone_number("");
          setmsg_subject("");
          setmessage("");
          window.scrollTo(0, 0);
        }
      });
  }

  const [Qustion, setQustion] = useState(1);
  function Update(Show) {
    setQustion(Show);
  }
  const [OpenDilog, setOpenDilog] = useState(false);
  const OepnHeandle = () => {
    setOpenDilog(true);
  };
  const CloseHeandle = () => {
    setOpenDilog(false);
  };
  const [topVideo, settopVideo] = useState();
  const openVideo = () => {
    settopVideo(true);
  };
  const CloseVideo = () => {
    settopVideo(false);
  };

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1025: { items: 2 },
  };
  const items = [
    <div className="item">
      <img src="/assets/products/Gallery_1.jpg" alt="images" />
    </div>,
    <div className="item">
      <img src="/assets/products/Gallery_2.jpg" alt="images" />
    </div>,
    <div className="item">
      <img src="/assets/products/Gallery_3.jpg" alt="images" />
    </div>,
    <div className="item">
      <img src="/assets/products/Gallery_4.jpg" alt="images" />
    </div>,
  ];

  const QustionData = [
    {
      Qustion: " HR professionals ",
      FaqAnswer:
        "HR professionals can use Reptile Tracko to efficiently track employee attendance, manage leaves, assign tasks, and streamline various HR processes within the organisation.",
    },
    {
      Qustion: "Manager",
      FaqAnswer:
        "Managers can utilise Reptile Tracko to monitor employee performance, track task progress, and ensure effective task management within their teams. It provides a centralised platform for better supervision and coordination .",
    },
    {
      Qustion: "Employee",
      FaqAnswer:
        "Employees can benefit from Reptile Tracko by easily managing their attendance records, requesting leaves, and staying organised with task assignments. It enhances communication and collaboration among team members. Benefit For HR Professionals.",
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="Porducts_main_section">
        {topVideo && (
          <div className="video_top_section">
            <div className="video_top_pop">
              <h3>Our Introduce Reptile Tracko</h3>
              <i
                class="fa fa-times"
                aria-hidden="true"
                onClick={CloseVideo}
              ></i>
            </div>
            <video controls>
              <source src="..Videos/video1.mp4" type="video/mp4" />
            </video>
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col-md-6 Porducts_text_section">
              <h3>Stay one step ahead with Reptile Tracko !</h3>
              <p
                style={{
                  color: "orangered",
                  paddingTop: "5px",
                  paddingBottom: "6px",
                }}
              >
                Signup to Start Tracking Your Field Force From Anywhere & Any
                Device
              </p>
              <p>
                Whether you have a small team or a large workforce, the Reptile
                Tracko Application is the perfect tool to boost your business's
                productivity and performance. Stay ahead of the competition with
                our cutting-edge technology and take your operations to the next
                level.
              </p>
              <div className="btn_section">
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      paddingBottom: "40px",
                    }}
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                    <p>Real-time tracking on the app</p>
                  </div>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <i class="fa fa-check" aria-hidden="true"></i>
                    <p>Real-time support</p>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      paddingBottom: "40px",
                    }}
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                    <p>Monitor Employees using mobile app</p>
                  </div>
                  <div style={{ display: "flex", gap: "5px", margin: "10px;" }}>
                    <i class="fa fa-check" aria-hidden="true"></i>
                    <p>Customer management</p>
                  </div>
                </div>
              </div>
              <div className="video_section">
                <button>
                  Get Start Now{" "}
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
                <div onClick={openVideo}>
                  <i class="fa fa-play" aria-hidden="true"></i>
                  <p onClick={openVideo}>Watch This Video</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 images_section_louction">
              <div style={{ position: "relative" }}>
                <img src="/assets/products/H1-Slider-2.png" alt="images" />
              </div>
              <div style={{ position: "absolute", top: "6%", left: "70%" }}>
                <img src="/assets/products/H1-Slider-1.png" alt="images" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main_Features_section">
        <div className="container  Features_section">
          <h2>Reptile tracko Application Features</h2>
          <p>
            Reptile Tracko is a comprehensive mobile application that combines
            location tracking, attendance management, task management, and
            customer management features.
          </p>
          <div className="row">
            <div className="col-8 attendance_section_main">
              <div className="row">
                <div className="col-6 attendance_section">
                  <div>
                    <img
                      src="/assets/products/Attendance Management.png"
                      alt="images"
                    />
                  </div>
                  <div>
                    <h6>Attendance Management</h6>
                    <p>
                      it's not just about tracking who is present or absent, but
                      also about fostering a culture of accountability and
                      productivity.
                    </p>
                    <hr></hr>
                  </div>
                </div>
                <div className="col-6 attendance_section">
                  <div>
                    <img
                      src="/assets/products/Location Tracking.png"
                      alt="images"
                    />
                  </div>
                  <div>
                    <h6>Location Tracking</h6>
                    <p>
                      Real-time location tracking makes this possible. It’s not
                      just about maps and pins; it's about a seamless flow of
                      information that keeps you connected and secure.
                    </p>
                    <hr></hr>
                  </div>
                </div>
                <div className="col-6 attendance_section">
                  <div>
                    <img
                      src="/assets/products/Task Management.png"
                      alt="images"
                    />
                  </div>
                  <div>
                    <h6>Task Management</h6>
                    <p>
                      The art of task management is converting chaos into
                      structure. Setting goals, organising, and prioritising
                      will help you approach your to-do list with confidence.
                    </p>
                    <hr></hr>
                  </div>
                </div>
                <div className="col-6 attendance_section">
                  <div>
                    <img
                      src="/assets/products/Customer Management.png"
                      alt="images"
                    />
                  </div>
                  <div>
                    <h6>Customer Management</h6>
                    <p>
                      Truly understand your customers' needs and preferences.
                      Listening actively to their feedback can turn complaints
                      into opportunities for improvement.
                    </p>
                    <hr></hr>
                  </div>
                </div>
                <div className="col-6 attendance_section">
                  <div>
                    <img
                      src="/assets/products/Internet Connectivity.png"
                      alt="images"
                    />
                  </div>
                  <div>
                    <h6>Leave management</h6>
                    <p>
                      By striking a compromise between organisational mandates
                      and employee needs, leave management guarantees efficient
                      operations.
                    </p>
                    <hr></hr>
                  </div>
                </div>
                <div className="col-6 attendance_section">
                  <div>
                    <img
                      src="/assets/products/Check In Check Out.png"
                      alt="images"
                    />
                  </div>
                  <div>
                    <h6>Check-in/Check-out System</h6>
                    <p>
                      This system ensures accurate tracking of employee
                      attendance and provides data for payroll and HR
                      management.
                    </p>
                    <hr></hr>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 images_section_features">
              <img
                src="/assets/products/1_gWpNzSpEdrp1zN0EMt7OWQ.webp"
                alt="images"
              />
              <div>
                <h5>Track, manage, and monitor with Reptile Tracko</h5>
                <p>
                  Introducing the Reptile Tracko Application, the ultimate
                  solution for real-time tracking and efficient task management
                  for businesses of all sizes. Designed to streamline your
                  operations and boost productivity, this innovative app allows
                  you to effortlessly monitor your employees' location, tasks,
                  attendance, and leaves with just a few clicks.
                </p>
                <div className="amizing">
                  <h6>Amazing Features</h6>
                  <h6>Read More</h6>
                </div>
              </div>
              <div className="slde_section_featurecs">
                <AliceCarousel
                  autoPlay
                  mouseTracking
                  items={items}
                  responsive={responsive}
                  controlsStrategy="alternate"
                  animationDuration={1000}
                  animationType="fadeout"
                  infinite
                  touchTracking={false}
                  disableDotsControls
                  disableButtonsControls
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container Slide_section_gellory">
        <div className="row">
          <h2>Reptile tracko Application Gallery </h2>
          <p>
            Designed to streamline your operations and boost productivity, this
            innovative app allows you to effortlessly monitor your employees'
            location, tasks,attendance, and leaves with just a few clicks.
          </p>
          <div className="col-4 slide_text_section">
            {/* <h2>Reptile tracko Application Features</h2> */}
            <p>
              Keep track of your team's attendance and leave effortlessly,
              eliminating the need for manual timesheets and reducing the risk
              of errors. By automating these processes, you can focus on more
              strategic tasks and streamline your HR operations for improved
              efficiency.Whether you have a small team or a large workforce, the
              Reptile Tracko Application is the perfect tool to boost your
              business's productivity and performance. Stay ahead of the
              competition with our cutting-edge technology and take your
              operations to the next level. Try the Reptile Tracko Application
              today and experience the difference it can make for your business.
            </p>
          </div>
          <div className="col-8 images_secton_gellory">
            <AliceCarousel
              autoPlay
              mouseTracking
              items={items}
              responsive={responsive}
              controlsStrategy="alternate"
              animationDuration={1000}
              animationType="fadeout"
              infinite
              touchTracking={false}
              disableDotsControls
              disableButtonsControls
            />
          </div>
        </div>
      </div>
      <div className="container prices_section_main">
        <div className="row">
          {OpenDilog && (
            <div className="moduel_section_div">
              <div className="dilog_section">
                <h3>Basic Plan</h3>
                <i class="fa fa-times" onClick={CloseHeandle}></i>
              </div>
              <div>
                <video controls>
                  <source src="/assets/products/video.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          )}

          <div>
            <h2>Choose the plan & Packages</h2>
            <p>
              Explore our range of subscription plans tailored to meet your
              needs and preferences. Choose the plan that best fits your
              requirements and unlock a world of exclusive content, premium
              features, and personalized support. Read on to discover more about
              each subscription option
            </p>
          </div>
          <div className="col-md-3 Detail_prices_section">
            <div className="Detail_prices">
              <div className="Shadow_section"></div>
              <div className="Detail">
                <h3>Basic Plan </h3>
                <div className="prices">
                  <h2>
                    <i class="fa fa-inr" aria-hidden="true"></i> 300
                  </h2>
                  <p>Per user/ Month</p>
                </div>
                <div className="btn_prices_section">
                  <button>Purchase Now</button>
                  <button onClick={OepnHeandle}>
                    <i class="fa fa-play" aria-hidden="true"></i> Play
                  </button>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Employee Tracking </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Task Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p> Attendance Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Leave Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Order Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Holiday Management</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>
                    UPTO <del>20 Users</del> 5 Users
                  </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>
                    <del>Payroll</del>
                  </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Minimum 10 Employees</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Basic customer support</p>
                </div>
                <div className="Features">
                  <p style={{fontSize: "17px",fontWeight:'500' }}>
                    <i class="fa fa-star" aria-hidden="true"></i>{" "}
                    <i class="fa fa-star" aria-hidden="true"></i> Billing will
                    be Quarterly.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 Detail_prices_section">
            <div className="Detail_prices">
              <div className="Shadow_section_tow"></div>
              <div className="Detail">
                <h3>Basic Plan Pro</h3>
                <div className="prices">
                  <h2 style={{ color: "rgb(5, 178, 184)" }}>
                    <i class="fa fa-inr" aria-hidden="true"></i> 350
                  </h2>
                  <p>Per user/ Month</p>
                </div>
                <div className="btn_prices_section">
                  <button style={{ background: "rgb(5, 178, 184)" }}>
                    Purchase Now
                  </button>
                  <button
                    style={{ background: "rgb(5, 178, 184)" }}
                    onClick={OepnHeandle}
                  >
                    <i class="fa fa-play" aria-hidden="true"></i> Play
                  </button>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Employee Tracking </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Task Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p> Attendance Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Leave Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Order Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Holiday Management</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>UPTO 20 Users</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <del>
                    <p>Payroll</p>
                  </del>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Minimum 10 Employees</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p> Priority customer support</p>
                </div>
                <div className="Features">
                  <p style={{fontSize: "17px",fontWeight:'500' }}>
                    <i class="fa fa-star" aria-hidden="true"></i>{" "}
                    <i class="fa fa-star" aria-hidden="true"></i> Billing will
                    be Quarterly.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 Detail_prices_section">
            <div className="Detail_prices">
              <div className="Shadow_section_thard"></div>
              <div className="Detail">
                <h3>Standard Plan</h3>
                <div className="prices">
                  <h2 style={{ color: "rgb(240, 80, 6)" }}>
                    <i class="fa fa-inr" aria-hidden="true"></i>500
                  </h2>
                  <p>Per user/ Month</p>
                </div>
                <div className="btn_prices_section">
                  <button style={{ background: "rgb(240, 80, 6)" }}>
                    Purchase Now
                  </button>
                  <button
                    style={{ background: "rgb(240, 80, 6)" }}
                    onClick={OepnHeandle}
                  >
                    <i class="fa fa-play" aria-hidden="true"></i> Play
                  </button>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Employee Tracking </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Task Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p> Attendance Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Leave Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Order Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Holiday Management</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>
                    UPTO <del>20 Users</del> 5 Users
                  </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Payroll</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Minimum 10 Employees</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Custom Order Form</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Basic customer support</p>
                </div>
                <div className="Features">
                  <p style={{fontSize: "17px",fontWeight:'500' }}>
                    <i class="fa fa-star" aria-hidden="true"></i>{" "}
                    <i class="fa fa-star" aria-hidden="true"></i> Billing will
                    be Quarterly.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 Detail_prices_section">
            <div className="Detail_prices">
              <div className="Shadow_section_fourthed"></div>
              <div className="Detail">
                <h3>Standard Plan Pro</h3>
                <div className="prices">
                  <h2 style={{ color: "rgb(6, 41, 240)" }}>
                    <i class="fa fa-inr" aria-hidden="true"></i> 550
                  </h2>
                  <p>Per user/ Month</p>
                </div>
                <div className="btn_prices_section">
                  <button style={{ background: "rgb(6, 41, 240)" }}>
                    Purchase Now
                  </button>
                  <button
                    style={{ background: "rgb(6, 41, 240)" }}
                    onClick={OepnHeandle}
                  >
                    <i class="fa fa-play" aria-hidden="true"></i> Play
                  </button>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Employee Tracking </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Task Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p> Attendance Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Leave Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Order Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Holiday Management</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>UPTO 20 Users</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Payroll</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Minimum 10 Employees</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p> Priority customer support</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Custom Order Form</p>
                </div>
                <div className="Features">
                  <p style={{fontSize: "17px",fontWeight:'500' }}>
                    <i class="fa fa-star" aria-hidden="true"></i>{" "}
                    <i class="fa fa-star" aria-hidden="true"></i> Billing will
                    be Quarterly.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container prices_section_main">
        <div className="row">
          {OpenDilog && (
            <div className="moduel_section_div">
              <div className="dilog_section">
                <h3>Basic Plan</h3>
                <i class="fa fa-times" onClick={CloseHeandle}></i>
              </div>
              <div>
                <video controls>
                  <source src="/assets/products/video.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          )}

          <div className="col-md-3 Detail_prices_section">
            <div className="Detail_prices">
              <div className="Shadow_section"></div>
              <div className="Detail">
                <h3>Premium Plan</h3>
                <div className="prices">
                  <h2>
                    <i class="fa fa-inr" aria-hidden="true"></i>3,000
                  </h2>
                  <p>Per user/ Year</p>
                </div>
                <div className="btn_prices_section">
                  <button>Purchase Now</button>
                  <button onClick={OepnHeandle}>
                    <i class="fa fa-play" aria-hidden="true"></i> Play
                  </button>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Employee Tracking </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Task Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p> Attendance Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Leave Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Order Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Holiday Management</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>
                    UPTO <del>20 Users</del> 5 Users
                  </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>
                    <del>Payroll</del>
                  </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Minimum 10 Employees</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Basic customer support</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 Detail_prices_section">
            <div className="Detail_prices">
              <div className="Shadow_section_tow"></div>
              <div className="Detail">
                <h3> Premium Plan Pro</h3>
                <div className="prices">
                  <h2 style={{ color: "rgb(5, 178, 184)" }}>
                    <i class="fa fa-inr" aria-hidden="true"></i>3,500
                  </h2>
                  <p>Per user/ Year</p>
                </div>
                <div className="btn_prices_section">
                  <button style={{ background: "rgb(5, 178, 184)" }}>
                    Purchase Now
                  </button>
                  <button
                    style={{ background: "rgb(5, 178, 184)" }}
                    onClick={OepnHeandle}
                  >
                    <i class="fa fa-play" aria-hidden="true"></i> Play
                  </button>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Employee Tracking </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Task Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p> Attendance Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Leave Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Order Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Holiday Management</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>UPTO 20 Users</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>
                    <del>Payroll</del>
                  </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Minimum 10 Employees</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p> Priority customer support</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 Detail_prices_section">
            <div className="Detail_prices">
              <div className="Shadow_section_thard"></div>
              <div className="Detail">
                <h3> Ultimate Plan</h3>
                <div className="prices">
                  <h2 style={{ color: "rgb(240, 80, 6)" }}>
                    <i class="fa fa-inr" aria-hidden="true"></i> 5,000
                  </h2>
                  <p>Per user/ Year</p>
                </div>
                <div className="btn_prices_section">
                  <button style={{ background: "rgb(240, 80, 6)" }}>
                    Purchase Now
                  </button>
                  <button
                    style={{ background: "rgb(240, 80, 6)" }}
                    onClick={OepnHeandle}
                  >
                    <i class="fa fa-play" aria-hidden="true"></i> Play
                  </button>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Employee Tracking </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Task Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p> Attendance Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Leave Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Order Management </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Holiday Management</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>
                    UPTO <del>20 Users</del> 5 Users
                  </p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Payroll</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Minimum 10 Employees</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Basic customer support</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Custom Order Form</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 Detail_prices_section">
            <div className="Detail_prices">
              <div className="Shadow_section_fourthed"></div>
              <div className="Detail">
                <h3>Ultimate Plan Pro</h3>
                <div className="prices">
                  <h2 style={{ color: "rgb(6, 41, 240)" }}>
                    <i class="fa fa-inr" aria-hidden="true"></i> 5,500
                  </h2>
                  <p>Per user/ Year</p>
                </div>
                <div className="btn_prices_section">
                  <button style={{ background: "rgb(6, 41, 240)" }}>
                    Purchase Now
                  </button>
                  <button
                    style={{ background: "rgb(6, 41, 240)" }}
                    onClick={OepnHeandle}
                  >
                    <i class="fa fa-play" aria-hidden="true"></i> Play
                  </button>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Employee Tracking </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Task Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p> Attendance Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Leave Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Order Management </p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Holiday Management</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>UPTO 20 Users</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Payroll</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p>Minimum 10 Employees</p>
                </div>
                <div className="Features">
                  <i
                    class="fa fa-hand-o-right"
                    aria-hidden="true"
                    style={{ color: "rgb(5, 178, 184)" }}
                  ></i>
                  <p> Priority customer support</p>
                </div>
                <div className="Features">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <p>Custom Order Form</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container Qustion_section_main">
        <div className="row">
          <h2>Reptile Tracko FAQ</h2>
          <p>
            Reptile Tracko can be customised to adapt to the unique requirements
            of organisations. It offers flexibility in configuring features and
            settings to align with the workflows and processes of different
            businesses.
          </p>
          <div className="col-5 Qustion_images_section">
            <img src="/assets/products/Questions-pana.svg" alt="Qustion" />
          </div>
          <div className="col-7 Qustion_text_section_main">
            {QustionData.map((item, index) => (
              <div className="Qustion_text_section_main_secound" key={index}>
                <div className="Heading_section">
                  <h4>{item.Qustion}</h4>
                  <i
                    class="fa fa-angle-double-down"
                    onClick={() => Update(1)}
                  ></i>
                </div>

                <div
                  className={Qustion === 1 ? "contant_show" : "contant_section"}
                >
                  <p>{item.FaqAnswer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="Form_section">
        <div className="five_section_form">
          <div className="container">
            <div className="row">
              <div className="col-md-6 form_images_section">
                <div className="icon_section_text">
                  <p>
                    Explore the world of reptiles with Reptile Tracko - your
                    ultimate companion for reptile enthusiasts. Discover a
                    wealth of information, resources, and tools to help you
                    learn more about these fascinating creatures and enhance
                    your reptile-keeping experience.
                  </p>
                  <div className="fa_section">
                    <i class="fa fa-street-view" aria-hidden="true"></i>
                    <h6>
                      Carecone Technologies Private Limited <br></br>265, 2nd
                      Floor, Aggarwal City Plaza
                    </h6>
                  </div>

                  <div className="fa_section">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                    <h6>9311572747</h6>
                  </div>

                  <div className="fa_section">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    <h6>sales@reptileindia.co.in</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 form_section_right">
                <div>
                  <div className="top_section">
                    <h2>Interested in Working With Us?</h2>
                    <p>
                      Drop us a line. We'd love to know more about your project.
                    </p>
                  </div>
                  <form>
                    <div className="main_form_section">
                      <div>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name*"
                          value={name}
                          onChange={(e) => {
                            setname(e.target.value);
                          }}
                        />

                        <input
                          type="email"
                          name="name"
                          placeholder="Email Address*"
                          value={email}
                          onChange={(e) => {
                            setemail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="main_form_section">
                      <div>
                        <input
                          type="number"
                          name="name"
                          placeholder="Phone Number*"
                          value={phone_number}
                          onChange={(e) => {
                            setphone_number(e.target.value);
                          }}
                        />
                        <input
                          type="text"
                          name="name"
                          placeholder="Subject*"
                          value={msg_subject}
                          onChange={(e) => {
                            setmsg_subject(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <textarea
                      name=""
                      id=""
                      placeholder="Message*"
                      value={message}
                      onChange={(e) => {
                        setmessage(e.target.value);
                      }}
                    />

                    <input
                      type="button"
                      value="Submit"
                      className="Submit"
                      onClick={() => {
                        handalSubmit();
                      }}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Products;
