import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./Components/index";
import Aboutus from "./Components/aboutUs";
import Portfolio from "./Components/Portfolio";
import Career from "./Components/career";
import Contact from "./Components/Contact";
import SwiperSlider from "./Components/SwiperSlider";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermCondition from "./Components/TermCondition";
import RefundPolicy from "./Components/RefundPolicy";
import Sliderimg from "./Components/Sliderimg";
import Industries from "./Components/Industries";
import DynamicPage from './Components/DynamicPage';
import BlogHome from "./Components/BlogHome";
import Products from "./Components/Porduct";
import SocialMedia from'./Components/BlogHome/mobile'
function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<> <Index /> <DynamicPage path="home" url="" />  </> }> </Route>
          <Route exact path="/about-us" element={<> <Aboutus /> <DynamicPage path="about_us" url="about-us" /></> }> </Route>
          <Route exact path="/contact-us" element={<> <Contact /> <DynamicPage path="contact_us" url="contact-us" /></> }> </Route>
          <Route exact path="/portfolio" element={<> <Portfolio /> <DynamicPage path="portfolio" url="portfolio" /></> }> </Route>
          <Route exact path="/refund-policy" element={<> <RefundPolicy /> <DynamicPage path="refund_policy" url="refund-policy" /></> }> </Route>
          <Route exact path="/terms-condition" element={<> <TermCondition /> <DynamicPage path="term_condition" url="terms-condition" /></> }> </Route>
          <Route exact path="/privacy-policy" element={<> <PrivacyPolicy /> <DynamicPage path="privacy_policy" url="privacy-policy" /></> }> </Route>
          <Route exact path="/career" element={<> <Career /> <DynamicPage path="career" url="career" /></> }> </Route>
          <Route path="/SwiperSlider" element={<SwiperSlider />}></Route>
          <Route path="/Sliderimg" element={<Sliderimg />}></Route>
          <Route path="/Industries" element={<Industries />}></Route>
          <Route path="/blog" element={<BlogHome />}></Route>
          <Route path="our-blog/:id" element={<SocialMedia />} />
          <Route path="/our-products/:id" element={<><Products /> 
          <DynamicPage path="reptile_tracko" url="our-products" /></> }></Route>


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;