import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/footer";
import { getBaseUrl } from "./../utils";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './aboutus.css';


function Portfolio() {
  useEffect(() => {
     window.scrollTo(0, 0);
    AOS.init({
      duration: 1200, // Customize the duration as needed
    });
  }, []);
  return (
    <Fragment>
      <>
        <>
          <Navbar />
          <>
          <>
          <section
  className="d-flex align-items-center page-hero  inner-page-hero "
  id="page-hero"
  style={{
    backgroundImage: 'url("assets/assets/images/solutionbanner.png")',
    marginTop: 90
  }}
>
  <div
    className="overlay-photo-image-bg parallax"
    data-bg-img="assets/assets/images/hero/inner-page-hero.jpg"
    data-bg-opacity={1}
  />
  <div className="overlay-color" data-bg-opacity=".75" />
  <div className="container">
    <div className="hero-text-area centerd">
      <h1 className="hero-title  " data-wow-delay=".2s">
        Our Portfolio
      </h1>
      <nav aria-label="breadcrumb ">
        <ul className="breadcrumb " data-wow-delay=".6s">
          <li className="breadcrumb-item">
            <a className="breadcrumb-link" >
              <i className="bi bi-house icon " />
              home
            </a>
          </li>
          <li className="breadcrumb-item active">Our Portfolio</li>
        </ul>
      </nav>
    </div>
  </div>
</section>


  <section className="about mega-section" id="about">
    <div className="container">
      <div className="content-block" style={{ marginBottom: 0 }}>
        <div className="row">
          <div
            className="col-12 col-lg-6 d-flex align-items-center order-1 order-lg-0 about-col pad-end"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={500}
          >
            <div className="text-area ">
              <div className="sec-heading  light-title ">
                <div className="content-area">
                  <h2 style={{ fontSize: 45 }}>
                    <span className="hollow-text">Astrology</span>
                  </h2>
                </div>
              </div>
              <p className="about-text">
                Reptile have a solid 1.5+ years of experience in mobile app
                development and can deploy various features in your astrology
                apps. Let’s have a look at some of the features that we can
                include in your astrology app-
              </p>
              <ul>
                <li>Love Horoscope : </li>
                <p className="about-text">
                  Your customers can connect with astrologers and discuss their
                  love life queries. Moreover, we can also add a section wherein
                  astrologers can write descriptions around different zodiac
                  signs and their love interests.
                </p>
                <li>Horoscope Reports : </li>
                <p className="about-text">
                  This feature would contain brief information on a person’s
                  daily, weekly, monthly, and yearly happenings as per their
                  zodiac signs. Additionally, the customers can even talk to an
                  astrologer through voice, video, or text chat and get more
                  information.
                </p>
                <li>Health &amp; Wealth Report : </li>
                <p className="about-text">
                  A report that would include all the information related to a
                  customer's health and wealth. Here too the customers can talk
                  with astrologers or just read the report posted by astrologers
                  on the mobile app page.
                </p>
                <li>Connect With Astrologers : </li>
                <p className="about-text">
                  Your customers can connect with an astrologer to get an
                  insight into the coming future. You can charge the customers
                  on per minute interaction.
                </p>
                <li>Marriage Report : </li>
                <p className="about-text">
                  We can also include a marriage report feature where your
                  customers can easily match different “kundlis” ( comparison
                  between two natal charts ) and check their compatibility.
                  Additionally, you can even provide dates on which your
                  customers can get engaged or married.
                </p>
                <li>And Many More: </li>
                <p className="about-text">On Demand Service by the Customer</p>
              </ul>
              <div className="cta-area">
                <a className=" btn-solid reveal-start">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-lg-6 d-flex align-items-center order-0 order-lg-1 about-col"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={500}
          >
            <div className="img-area  " data-tilt="">
              <div className="image   ">
                <img
                  className="about-img img-fluid "
                  loading="lazy"
                  src="assets/assets/images/website/astrology.png"
                  alt="Our vision"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block">
        <div className="row">
          <div
            className="col-12 col-lg-6 d-flex align-items-center about-col  "
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={500}
          >
            <div className="img-area  ">
              <div className="image  " data-tilt="">
                <img
                  className="about-img img-fluid "
                  loading="lazy"
                  src="assets/assets/images/website/App_Development_2.jpg"
                  alt="about"
                />
              </div>
            </div>
          </div>
          <div
            className="col-12 col-lg-6 d-flex align-items-center about-col pad-start   "
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={500}
          >
            <div className="text-area ">
              <div className="sec-heading  light-title ">
                <div className="content-area">
                  <h2
                    style={{ fontSize: 40 }}
                    className=" title "
                    data-wow-delay=".4s"
                  >
                    <span className="hollow-text"> Education</span>
                  </h2>
                </div>
              </div>
              <p className="about-text">
                Our off the shelf mobile app solutions facilitates the best
                learning methodology in the real test environment to identify
                weaknesses and boost overall performance.
              </p>
              <ul>
                <li>Subject Material : </li>
                <p className="about-text">
                  Get instant access to the full library of subject material,
                  choose your subjects and set the goals to unlock customized
                  tests and exam resources handcrafted by industry best
                  teachers.
                </p>
                <li>Take Daily or Weekly Tests : </li>
                <p className="about-text">
                  Attempt full test series of your target exam(s) in the real
                  environment to correct mistakes in a practice session.
                  Schedule daily or weekly test series to strengthen the
                  understanding of core concepts.
                </p>
                <li>Get Results &amp; Insight Instantly : </li>
                <p className="about-text">
                  Evaluate your learning curve with interactive graphs and chart
                  bar to identify weaknesses and boost overall test performance.
                  Re-attempt concept based tests and question cruise to build
                  exam readiness.
                </p>
                <li>LiveClass: </li>
                <p className="about-text">
                  Student Can attend liveclasses happened by Teacher.
                </p>
                <li>And Many More: </li>
                <p className="about-text">On Demand Service by the Customer</p>
              </ul>
              <div className="cta-area ">
                <a
                  className=" btn-solid "
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="about mega-section" id="about">
    <div className="container">
      <div className="content-block" style={{ marginBottom: 0 }}>
        <div className="row">
          <div
            className="col-12 col-lg-6 d-flex align-items-center order-1 order-lg-0 about-col pad-end   "
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={500}
          >
            <div className="text-area ">
              <div className="sec-heading  light-title ">
                <div className="content-area">
                  <h2 style={{ fontSize: 45 }}>
                    <span className="hollow-text">Food Delivery </span>
                  </h2>
                </div>
              </div>
              <p className="about-text">
                Online food delivery applications facilitate restaurants to
                deliver food orders to customers' doorsteps. Customers can
                easily search for their favorite restaurants online and place
                orders online.
              </p>
              <ul>
                <li>
                  <span>Sign Up</span>
                  <p className="about-text">
                    Customers sign up on the online food ordering app to search
                    for restaurants and browse various cuisines and meals
                    offered.
                  </p>
                </li>
                <li>
                  <span>Browse Menu</span>
                  <p className="about-text">
                    After choosing a restaurant, customers browse the menu by
                    adding dishes to the cart to prepare their food order.
                  </p>
                </li>
                <li>
                  <span>Place Order</span>
                  <p className="about-text">
                    Once the order is complete, customers can choose a payment
                    method and pay to place an order.
                  </p>
                </li>
                <li>
                  <span>Order Preparation</span>
                  <p className="about-text">
                    The Restaurant Owner receives the order notification and
                    forward the order details to the kitchen to get the order
                    prepared.
                  </p>
                </li>
                <li>
                  <span>Delivery</span>
                  <p className="about-text">
                    The restaurant owner contacts the driver-partner to deliver
                    the order once ready. The app allows tracking the order in
                    real time.
                  </p>
                </li>
                <li>
                  <span>Ratings &amp; Feedback</span>
                  <p className="about-text">
                    Customers can share ratings about the food quality, taste,
                    and delivery via the same application.
                  </p>
                </li>
              </ul>
              <div className="cta-area">
                <a className=" btn-solid reveal-start">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-lg-6 d-flex align-items-center order-0 order-lg-1 about-col  "
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={500}
          >
            <div className="img-area  " data-tilt="">
              <div className="image   ">
                <img
                  className="about-img img-fluid "
                  loading="lazy"
                  src="assets/assets/images/website/fooddelivery.jpg"
                  alt="Our vision"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block">
        <div className="row">
          <div
            className="col-12 col-lg-6 d-flex align-items-center about-col  "
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={500}
          >
            <div className="img-area  ">
              <div className="image  " data-tilt="">
                <img
                  className="about-img img-fluid "
                  loading="lazy"
                  src="assets/assets/images/website/grocery.jpg"
                  alt="about"
                />
              </div>
            </div>
          </div>
          <div
            className="col-12 col-lg-6 d-flex align-items-center about-col pad-start   "
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={500}
          >
            <div className="text-area ">
              <div className="sec-heading  light-title ">
                <div className="content-area">
                  <h2
                    style={{ fontSize: 40 }}
                    className=" title "
                    data-wow-delay=".4s"
                  >
                    <span className="hollow-text"> Grocery</span>
                  </h2>
                </div>
              </div>
              <ul>
                <li>
                  <span>Customer App</span>
                  <p className="about-text">
                    Getting started is quick and seamless with email and social
                    login options.Choose a wide range of food and grocery
                    products with a detailed description.Customers can pay the
                    way they want with support for all popular options.Real-time
                    tracking and alerts to know the exact status of an
                    order.Customers can choose a convenient delivery time to
                    receive their orders.
                  </p>
                </li>
                <li>
                  <span>Admin Panel</span>
                  <p className="about-text">
                    A one stop web admin to manage all aspects of your grocery
                    business.Run and manage multiple stores and handle them
                    individually in one place.Add managers to your stores to
                    simplify and streamline store management.Create product
                    listings, add pricing, quantity and other inventory
                    details.Manually assign new orders to your stores or let it
                    be handled automatically.Keep an eye on every order placed
                    and their fulfillment details.View and manage all customers
                    registered on the platform and do more.
                  </p>
                </li>
                <li>
                  <span>Store Manager Panel</span>
                  <p className="about-text">
                    Allow shoppers to browse and shop products from a specific
                    store.Make your stores easily discoverable on a map for easy
                    navigation.Provide option to order items online and pickup
                    from a nearby store.Power your doorstep delivery operations
                    with third party integration.Integrate with an inventory
                    management software for higher efficiency.
                  </p>
                </li>
                <li>
                  <span>Optional Feature</span>
                  <p className="about-text">
                    Store managers get a web interface for easy order
                    management.Managers get real-time web notifications on all
                    new orders received.Managers can dispatch an order from
                    their store for delivery.Track all store orders - new, in
                    progress and completed ones.Store managers can view and
                    manage their store inventory and pricing.
                  </p>
                </li>
              </ul>
              <div className="cta-area ">
                <a
                  className=" btn-solid "
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="about mega-section" id="about">
    <div className="container">
      <div className="content-block" style={{ marginBottom: 0 }}>
        <div className="row">
          <div
            className="col-12 col-lg-6 d-flex align-items-center order-1 order-lg-0 about-col pad-end   "
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={500}
          >
            <div className="text-area ">
              <div className="sec-heading  light-title ">
                <div className="content-area">
                  <h2 style={{ fontSize: 45 }}>
                    <span className="hollow-text">On Demand Service</span>
                  </h2>
                </div>
              </div>
              <p>
                In the fast-moving world of the internet, on-demand app
                solutions are the preeminent way to capture the attention for
                your business in the crowd. We are an On Demand App Development
                Company behind the bunch of leading interactive applications
                launched for today’s users. We accelerate your digital roadmap
                by blending your idea with simplicity and real-timeliness of our
                on-demand app development skills to deliver an app used by each
                house on the planet.
              </p>
              <ul>
                <li>
                  <span>OTT Platform Development</span>
                  <p className="about-text">
                    Experience the power of On-Demand OTT development services,
                    tailored to your unique needs, as we help you unlock new
                    possibilities and deliver immersive content experiences to
                    your audience.
                  </p>
                </li>
                <li>
                  <span>Travel App Development</span>
                  <p className="about-text">
                    We create innovative travel app development solutions for
                    the dynamic nomads to enjoy their travel journey.
                  </p>
                </li>
              </ul>
              <div className="cta-area">
                <a className=" btn-solid reveal-start">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-lg-6 d-flex align-items-center order-0 order-lg-1 about-col  "
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration={500}
          >
            <div className="img-area  " data-tilt="">
              <div className="image   ">
                <img
                  className="about-img img-fluid "
                  loading="lazy"
                  src="assets/assets/images/website/App_Development_5.jpg"
                  alt="Our vision"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="services services-boxed mega-section mt-5">
    <div className="container">
      <div className="row gx-4 gy-4 services-row">
        <div className="col-12">
          <h2 className="text-center">Technology Stack</h2>
          <div className="row">
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/php.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                PHP
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/reactjs.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                React JS
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/nodejs.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Node JS
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/Flutter.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Flutter
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/IOS.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                IOS
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/android.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Android
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/Angular.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Angular
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/nextjs.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Next JS
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/AWS.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                AWS
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/azure.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                AZURE
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/Wordpress.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Wordpress
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/testing.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Testing
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/GCP.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                GCP
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/Shopify.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Shopify
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/Laravel.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Laravel
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/Codeigniter.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Codeigniter
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/Kotlin.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Kotlin
              </h2>
            </div>
            <div className="col-md-2 mt-5">
              <img
                src="assets/assets/images/website/techstack/Swift.png"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: 200,
                  width: 200
                }}
              />
              <h2
                className="hero-title text-center"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                Swift
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

 
</>

        <Footer />
        </>

      </>

    </Fragment>
  )
}
export default Portfolio;